<template>
  <!-- 弹框 -->
  <el-dialog title="选择商品" :visible.sync="show" :before-close="cancelSelect">

    <!-- 顶部搜索 -->
    <div class="search">
      <el-input v-model="productValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
        clearable></el-input>
      <el-input v-model="orderValue" placeholder="搜索商品编号" suffix-icon="el-icon-search" @change="onSearch"
        clearable></el-input>
    </div>

    <div class="globle_table">
      <el-table :data="tableData" max-height="540" style="width: 100%;" @row-click="singleElection"
        highlight-current-row>
        <el-table-column align="center" width="55" label="">
          <template slot-scope="scope">
            <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
            <el-radio class="radio" v-model="templateSelection" :label="scope.row.id">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="序号" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
        <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
        <el-table-column show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
        <el-table-column show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
        <el-table-column show-overflow-tooltip prop="specs" label="规格"></el-table-column>
        <el-table-column show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
        <el-table-column show-overflow-tooltip prop="tenderProductPrice" label="招标价格"></el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper,sizes, total" :total="totalItemsCount">
      </el-pagination>
    </div>
    <span slot="footer">
      <el-button @click="cancelSelect">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productValue: '', // 搜索商品名称
      orderValue: '', // 搜索商品编号
      tableData: [],
      //   当前选择的行的id
      templateSelection: "",
      //   当前选择的行的数据
      checkList: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0,
    };
  },
  created() {
    // this.queryProductList();
  },
  mounted() { },
  methods: {
    // 获取列表
    queryProductList() {
      this.loading = true;
      this.$axios.get(this.$api.biddingSuppliedGoodsList, {
        params: {
          productName: this.productValue || null, // 商品名称
          serialId: this.orderValue || null, // 商品编号
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: 1,
          selectType: 1,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 重置
    resetData() {
      this.templateSelection = '';
      this.checkList = [];
    },
    // 取消
    cancelSelect() {
      this.$emit("cancelSelect");
      this.resetData();
    },
    // 确定
    handleSubmit() {
      this.$emit("handleSubmit", this.checkList);
      this.resetData();
    },
    // 单选
    singleElection(row) {
      this.templateSelection = row.id
      this.checkList = this.tableData.filter((item) => item.id === row.id)
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.queryProductList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.queryProductList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.queryProductList();
    },
  }
};
</script>

<style lang="scss" scoped>
.pagebox {

  /deep/ .el-input,
  /deep/ .el-input__inner {
    width: 90px;
  }

}

.search {
  margin-bottom: 20px;
  display: flex;

  /deep/.el-input {
    width: 255px;
    margin-right: 20px;
  }
}
</style>