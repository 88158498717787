<!-- 调价列表 -->

<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="statusValue" placeholder="全部状态" @change="handleSelectStatus" clearable>
          <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="orderValue" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" plain type="primary" @click="downloadTemplate">下载模板</el-button>
        <el-button class="btn" type="primary" @click="importShow = true">导入申请</el-button>
        <el-button class="btn" type="primary" @click="handleApply">申请调价</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" max-height="540" style="width: 100%;" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"
            width="200"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"
            width="200"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="oldPrice" label="招标价格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="newPrice" label="新价格"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="xxx" label="申请来源"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="adjustReason" label="调价原因"
            width="150"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="申请时间"
            width="150"></el-table-column>
          <el-table-column prop="status" label="审核状态" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '审核通过' : '审核不通过' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="auditTime" label="审核时间" width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.auditTime ? scope.row.auditTime : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 申请调价弹窗 -->
    <el-dialog title="申请调价" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" placeholder="请选择" readonly></el-input>
              <el-button size="small" type="primary" @click="handleOpen">选择</el-button>
            </el-form-item>
            <el-form-item label="商品分类" prop="category">
              <el-input v-model="ruleForm.category" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="招标价格" prop="tenderProductPrice">
              <el-input v-model="ruleForm.tenderProductPrice" placeholder="自动带出" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="scale">
              <el-input v-model="ruleForm.scale" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="新价格" prop="newPrice">
              <el-input v-model="ruleForm.newPrice" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="调价原因" prop="reason">
              <el-input v-model="ruleForm.reason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">申请调价</el-button>
      </div>
    </el-dialog>

    <!-- 导入申请弹窗 -->
    <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls"
                :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed"
                :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red;">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="申请原因" required>
              <el-input v-model="applyReason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择商品弹窗 -->
    <SelectDialog ref="sonComponent" :show="selectDialogShow" @cancelSelect="cancelSelect" @handleSubmit="handleSubmit"
      @handleOpen="handleOpen"></SelectDialog>
  </div>
</template>

<script>
import SelectDialog from './components/selectDialog.vue';
export default {
  components: { SelectDialog },
  data() {
    return {
      loading: false,
      statusOptions: [{
        value: '0',
        label: '待审核'
      }, {
        value: '2',
        label: '不通过'
      }, {
        value: '1',
        label: '审核通过'
      },],
      statusValue: '', // 全部状态下拉
      orderValue: '', // 搜索商品条形码
      productValue: '', // 搜索商品名称
      // 选择商品弹窗
      selectDialogShow: false,
      checkData: {}, // 选择的数据
      // 申请调价弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        productName: '',  // 商品名称
        barCode: '',  // 商品条形码
        category: '',  // 商品分类
        scale: '',  // 规格
        tenderProductPrice: '',  // 招标价格
        newPrice: '',  // 新价格
        reason: '',  // 调价原因
        categoryId: '', // 分类id
        productSupplierId: ''
      },
      rules: {
        productName: [{ required: true, message: '请选择商品名称', trigger: 'blur' }],
        newPrice: [{ required: true, message: '请输入新价格', trigger: 'blur' }],
        reason: [{ required: true, message: '请输入调价原因', trigger: 'blur' }],
      },
      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importAdjustPrice,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importShow: false,
      importFiles: [],  //文件列表
      applyReason: '', // 申请原因
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getProductAdjustPriceList();
  },
  methods: {
    // 获取列表
    getProductAdjustPriceList() {
      this.loading = true;
      this.$axios.get(this.$api.getProductAdjustPriceList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          status: this.statusValue || null, // 全部状态
          barCode: this.orderValue || null, // 条形码
          productName: this.productValue || null, // 商品名称
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 打开弹窗
    handleOpen() {
      this.selectDialogShow = true
      this.$refs.sonComponent.queryProductList();
    },
    // 关闭选择商品弹窗
    cancelSelect() {
      this.selectDialogShow = false;
    },
    // 选择商品弹窗 确定
    handleSubmit(data) {
      console.log('data', data);

      this.checkData = data[0];
      this.selectDialogShow = false;
      // 清空productName的校验规则
      this.$refs['ruleForm'].clearValidate('productName');
      this.ruleForm.productName = this.checkData.productName;
      this.ruleForm.barCode = this.checkData.barCode;
      this.ruleForm.category = this.checkData.categoryName;
      this.ruleForm.tenderProductPrice = this.checkData.tenderProductPrice;
      this.ruleForm.scale = this.checkData.specs;
      this.ruleForm.categoryId = this.checkData.categoryId;
      this.ruleForm.productSupplierId = this.checkData.productSupplierId;
    },
    // 申请调价信息
    handleApply() {
      this.dialogVisible = true;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.addProductAdjustPrice, {
            adjustReason: this.ruleForm.reason,
            categoryId: this.ruleForm.categoryId,
            newPrice: this.ruleForm.newPrice,
            oldPrice: this.ruleForm.tenderProductPrice,
            productName: this.ruleForm.productName,
            barCode: this.ruleForm.barCode,
            specs: this.ruleForm.scale,
            productSupplierId: this.ruleForm.productSupplierId,
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('申请成功!');
              this.handleCloseDialog();
              this.getProductAdjustPriceList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 模板下载
    downloadTemplate() {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = "https://校园生活网.com/files/default/调价申请导入模版.xlsx";
      link.download = "调价申请导入模板.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 导入弹窗关闭
    onImportCancel() {
      this.importShow = false;
      this.importFiles = [];
      this.applyReason = '';
      this.getProductAdjustPriceList();
    },
    beforeUpload(file) {
      console.log(file);
      const suffixArr = ["xlsx", "xls"];
      const maxSize = 1024 * 1024 * 5;
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"xlsx"、"xls"文件`);
        this.importFiles = [];
        return false;
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过5M");
        this.importFiles = [];
        return false;
      }
      this.importFiles.push(file.raw)
    },
    handleExceed() {
      this.$message.warning(`只能上传一个文件！`);
    },
    handleRemove() {
      this.importFiles = [];
    },
    // 导入表单校验
    submitFormImport() {
      if (!this.importFiles || this.importFiles.length == 0) {
        return this.$message.warning('请先上传文件');
      }
      if (!this.applyReason) {
        return this.$message.warning('请填写申请原因');
      }
      let parmas = new FormData()
      parmas.append('file', this.importFiles[0])
      parmas.append('reason', this.applyReason)
      this.$axios.post(this.$api.importAdjustPrice, parmas).then((res) => {
        if (res.data.code == 100) {
          this.$message.success('导入成功!');
          this.onImportCancel();
        }
      })
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 全部状态下拉
    handleSelectStatus(value) {
      this.clearData();
      this.statusValue = value;
      this.getProductAdjustPriceList();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getProductAdjustPriceList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getProductAdjustPriceList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getProductAdjustPriceList();
    },
    // 查看
    handleCheck(row) {
      this.$router.push({ path: "detail/my", query: { id: row.id }, });
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 11) {
        if (row.status == '0') {
          return { color: 'red' }
        }
      }
    },
  }

}



</script>

<style scoped lang="scss">
.list {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-right: 20px;
    }

    .el-select {
      margin-right: 20px;
    }
  }


}

// 审核不通过原因弹窗
/deep/ .el-textarea__inner {
  resize: none;
  background-color: #f2f2f2;
  border-radius: 10px;
  height: 130px;
}

/deep/ .el-input__count {
  background-color: #f2f2f2;
}
</style>
